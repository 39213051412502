<template>
  <div>
    <div class="btnStop1" @click="create()">创建直播间</div>
    <div class="btnStop1" @click="start()">开始直播</div>
    <!-- <div class="prism-player" :id="playerId" :style="playStyle"></div> -->
  </div>
</template>

<script>
import { create, start } from "../../api/index";
export default {
  data() {
    return {};
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    //创建直播间
    async create() {
      console.info(window.sessionStorage.getItem("userAvatar"));
      let extends1 = {
        userAvatar: window.sessionStorage.getItem("userAvatar"),
        user_avatar: window.sessionStorage.getItem("userAvatar"),
        userNick: "健身",
      };
      let obj = {
        anchor: window.sessionStorage.getItem("userId"),
        anchor_nick: window.sessionStorage.getItem("userName"),
        coverUrl: "",
        extends: JSON.stringify(extends1),
        mode: 1,
        notice: "",
        password: "123456",
        title: "直播",
      };
      console.info(obj);
      const { data } = await create(obj);

      window.sessionStorage.setItem("chat_id", data.chat_id);
      // window.sessionStorage.getItem("data.chat_id");
    },
  },
};
</script>

<style scoped>
.prism-player {
  width: 100%;
  height: 800px;
  /* opacity: 0; */
  /* display: none; */
}

.btnStop {
  cursor: pointer;
  background: pink;
  position: absolute;
  bottom: 0;
}
</style>
